import React from 'react'
import TextSection from 'src/sections/TextSection'

const RulesGeneral = () => {
  return (
    <TextSection id="general" title="Основная идея">
      <p>{paragraph1}</p>
      <p>{paragraph2}</p>
      <p>{paragraph3}</p>
    </TextSection>
  )
}

export default RulesGeneral

const paragraph1 = `На карте отмечены памятники, парки, 
                    скверы, музеи, исторические здания и\xa0прочие интересные 
                    объекты города. Твоя цель\xa0– открыть как можно больше таких 
                    объектов. Чтобы открыть место, нужно подойти к\xa0нему, найти 
                    точку геолокации (или\xa0QR-код) и\xa0зачекиниться, нажав кнопку 
                    "Открыть место". Открыв место, тебе станет доступна информация 
                    об этом месте и\xa0фотографии.`
const paragraph2 = `Объекты подразделяются на три группы\xa0- красные, синие и\xa0зелёные 
                    в\xa0зависимости от тематической принадлежности и\xa0популярности. 
                    Открыв определенное количество мест, ты проходишь очередной 
                    Уровень или получаешь соответствующее Достижение.`
const paragraph3 = `Также, за открытые места, достижения начисляются баллы 
                    и\xa0формируется рейтинг игроков.`
