import styled from 'styled-components'
import { up } from 'src/utils/media'

interface Props {
  float?: string
}

const StyledH2 = styled.h2<Props>`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.colors.bodyText};
  font-size: 1.9rem;
  letter-spacing: .015em;
  line-height: 1.4;
  font-weight: 800;

  ${up('mobile')} {
    font-size: 2.2rem;
  }

  ${up('desktop')} {
    font-size: 2.5rem;
  }

  ${up('largeDesktop')} {
    font-size: 5rem;
  }

  ${props => props.float && `text-align: ${props.float};`}
`

export default StyledH2
