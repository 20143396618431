import React from 'react'
import { graphql, PageRendererProps } from 'gatsby'
import Layout from 'src/layout'
import Header from 'src/layout/Header'
import SEO from 'src/components/SEO'
import PageTitle from 'src/sections/PageTitle'
import RulesGeneral from 'src/sections/RulesGeneral'
import RulesLevels from 'src/sections/RulesLevels'
import RulesRating from 'src/sections/RulesRating'
import RulesAttention from 'src/sections/RulesAttention'

export interface IndexPageProps extends PageRendererProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

const RulesPage:React.FC<IndexPageProps> = props => {
  return (
    <Layout>
      <SEO
        url={props.location.href}
        title={`Правила - ${props.data.site.siteMetadata.title}`}
      />
      <Header small />
      <main>
        <PageTitle title="Правила" />
        <RulesGeneral />
        <RulesLevels />
        <RulesRating />
        <RulesAttention />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default RulesPage
