import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  width: ${props => props.theme.containerWidth.sm};
  margin: 0 auto;
  height: 100%;
  padding: 12rem 0 2rem 0;
  position: relative;
  flex: 0 0 auto;
  text-align: center;

  ${up('mobile')} {
    width: ${props => props.theme.containerWidth.md};
  }

  ${up('tablet')} {
    width: ${props => props.theme.containerWidth.lg};
    min-height: unset !important;
    height: auto;
  }

  ${up('desktop')} {      
    padding: 16rem 0 4rem 0;
  }

`

export default Wrapper
