import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  width: ${props => props.theme.containerWidth.sm};
  margin: 0 auto;
  height: 100%;
  padding: 4rem 0;
  position: relative;
  flex: 0 0 auto;

  ${up('mobile')} {
    width: ${props => props.theme.containerWidth.md};
  }

  ${up('tablet')} {
    flex-flow: row nowrap!important;
    padding: 4rem 0;
    width: ${props => props.theme.containerWidth.lg};

    min-height: unset!important;
    height: auto;
  }

  ${up('desktop')} {      
    max-width: 145rem;
  }

  ${up('largeDesktop')} {
    max-width: unset;
    padding-left: 20%;
    padding-right: 20%;
  }

  & > div {
    width: 100%;
    margin: 0;

    ${up('tablet')} {
      width: calc((100% - 8rem) / 3);
      margin-right: 4rem;
    }

  }

  div.title {
    margin-bottom: 3rem;

    span.attention {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: ${props => props.theme.colors.placeRed}!important;
    }

    ${up('tablet')} {
    margin-bottom: 0;

      & > h2 {
        margin-left: 25%;
      }
    }
  }

  div.body {
    display: flex;
    flex-flow: column;
    flex-shrink: 0;

    & p {
      margin-bottom: 3rem;

      &:nth-last-child(1) {
        margin-bottom: 0!important;
      }

      &.accent {
        color: ${props => props.theme.colors.secondary[500]};
      }

      &.address {
        font-size: 1.15em;
        letter-spacing: .015em;
        font-weight: 700;
        line-height: 1.15;
        color: ${props => props.theme.colors.primary};
        margin-bottom: 2rem;
      }
    }

    .spacing {
      height: 0;
      
      ${up('tablet')} {
        height: 2rem;
      }

      ${up('desktop')} {
        height: 5rem;
      }

      ${up('largeDesktop')} {
        height: 8rem;
      }
    }

   
  }

  .about {
    display: flex;
    flex-flow: column;
    flex-shrink: 0;

    & > p {
      font-size: 19px;
      margin-bottom: 4rem;
      font-weight: 800;
    }
    
    & > p.accent {
      margin-bottom: 2rem;
      max-width: 30rem;

      ${up('largeDesktop')} {
        max-width: 60rem;
      }
    }

    a {
      color: ${props => props.theme.colors.primary};
      transition: all .7s ease-out;

      &:hover, &:active {
        color: ${props => props.theme.colors.primaryLight};
      }
    }
  }

  .places {
    display: flex;
    flex-flow: row;
    flex: 1 1 100%;
    justify-content: flex-start;
    margin-left: 1rem;

    & + span {
      margin-left: 1rem;
    }
    
    & > div {
      display: flex;
      flex: 1 1 100%;
      flex-flow: row;
      text-align: left;
      align-items: center;
      justify-content: flex-start;
      margin: 0 2.8rem 1rem 0;

      &:nth-last-child(1) {
        margin-right: 0!important;
      }

      ${up('mobile')} {
        flex: 0 1 20%;
      }

      span {
        font-weight: 700;
      }

      & > svg {
        width: 2.6rem;
        height: 2.6rem;
        margin-right: 1.4rem;
      }

      ${up('largeDesktop')} {
        & > svg {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }

  .levels {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 0;
    
    & > div {
      width: 100%;
      height: 6rem;
      display: flex;
      flex-flow: column;
      margin: 1rem 0 4rem 0;
      position: relative;
      padding-left: 8rem;
      justify-content: center;

      &.level.wide {
        width: 100%;
      }

      & > svg, & > img {
        width: 5.5rem;
        height: 6rem;
        margin-bottom: 2rem;
        position: absolute;
        top: 0;
        left: 0;
      }

      ${up('tablet')} {
        & > svg, & > img {
          width: 5.5rem;
          height: 6rem;
        }
      }

      ${up('largeDesktop')} {
        & > svg, & > img {
          width: 11rem;
          height: 12rem;
        }
      }
    }

    &.shrinked {
      margin-bottom: 0;

      & > div {
        ${up('tablet')} {
          &:nth-last-child(1), &:nth-last-child(2) {
            /* margin-bottom: 0; */
          }
        }
      }
    }
  }

  span {
    color: ${props => props.theme.colors.secondary[500]};
    
    &.red {
      color: ${props => props.theme.colors.placeRed};
    }

    &.blue {
      color: ${props => props.theme.colors.placeBlue};
    }

    &.green {
      color: ${props => props.theme.colors.placeGreen};
    }

    &.primary {
      color: ${props => props.theme.colors.primary};
    }
  }

`

export default Wrapper
