import React from 'react'
import StyledH2 from './style'

interface Props {
  float?: string
  className?: string
  children: React.ReactNode
}

const H2 = (props: Props) => {
  return (
    <StyledH2
      float={props.float}
      className={props.className}
    >
      {props.children}
    </StyledH2>
  )
}

export default H2
