import React, { ReactNode } from 'react'
import H2 from 'src/components/Typography/H2'
import Wrapper from './style'

interface Props {
  id: string
  title: string | React.ReactNode
  children?: ReactNode
}

const TextSection:React.FC<Props> = (props) => {
  return (
    <Wrapper id={props.id}>
      <div className="title">
        <H2>{props.title}</H2>
      </div>
      <div className="body">
        {props.children}
      </div>
    </Wrapper>
  )
}

export default TextSection
