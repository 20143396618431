import React from 'react'
import TextSection from 'src/sections/TextSection'
//@ts-ignore
import PlaceRed from 'src/public/icons/PlaceRed.inline.svg'
//@ts-ignore
import PlaceBlue from 'src/public/icons/PlaceBlue.inline.svg'
//@ts-ignore
import PlaceGreen from 'src/public/icons/PlaceGreen.inline.svg'
//@ts-ignore
import First10Acheivements from 'src/public/icons/first-10-acheivements.inline.svg'
//@ts-ignore
import First50Objects from 'src/public/icons/first-50-objects.inline.svg'
//@ts-ignore
import AllBlue from 'src/public/icons/all-blue.inline.svg'
//@ts-ignore
import AllGwpMemorials from 'src/public/icons/all-gpw-memorials.inline.svg'
//@ts-ignore
import AllGreen from 'src/public/icons/all-green.inline.svg'
//@ts-ignore
import AllHistoricalBuildings from 'src/public/icons/all-historical-buildings.inline.svg'
//@ts-ignore
import AllMemorials from 'src/public/icons/all-memorials.inline.svg'
//@ts-ignore
import AllModernBuildings from 'src/public/icons/all-modern-buildings.inline.svg'
//@ts-ignore
import AllMuseums from 'src/public/icons/all-museums.inline.svg'
//@ts-ignore
import AllNatureMonuments from 'src/public/icons/all-nature-monuments.inline.svg'
//@ts-ignore
import AllObjects from 'src/public/icons/all-objects.inline.svg'
//@ts-ignore
import AllParksAndSquares from 'src/public/icons/all-parks-and-squares.inline.svg'
//@ts-ignore
import AllRed from 'src/public/icons/all-red.inline.svg'
//@ts-ignore
import AllWarshipPlaces from 'src/public/icons/all-warship-places.inline.svg'
//@ts-ignore
import Energizer from 'src/public/icons/energizer.inline.svg'
//@ts-ignore
import FirstObject from 'src/public/icons/first-object.inline.svg'

const RulesRating = () => {
  return (
    <TextSection id="rating" title="Достижения, баллы, рейтинг">
      <p>{paragraph1}</p>
      <p>{paragraph2}</p>

      <p>Достижения:</p>
      <div className="levels">
        <div className="level">
          <AllGreen />
          <span className="primary">За все зеленые объекты</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <AllBlue />
          <span className="primary">За все синие объекты</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <AllRed />
          <span className="primary">За все красные объекты</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <AllMuseums />
          <span className="primary">
            За все музеи в&nbsp;группах «Музеи» и&nbsp;«Музейные&nbsp;экспонаты»
          </span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <Energizer />
          <span className="primary">30 и&nbsp;более мест за один день</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <FirstObject />
          <span className="primary">За первое открытое место</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <AllGwpMemorials />
          <span className="primary">За все места категории «Памятники&nbsp;ВОВ»</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <AllMemorials />
          <span className="primary">За все места категории «Памятники»</span>
          <span>50 баллов</span>
        </div>
        <div className="level">
          <AllWarshipPlaces />
          <span className="primary">За все места категории «Культовые&nbsp;сооружения»</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <AllHistoricalBuildings />
          <span className="primary">За все места категории «Исторические&nbsp;здания»</span>
          <span>50 баллов</span>
        </div>
        <div className="level">
          <AllParksAndSquares />
          <span className="primary">За все места категории «Парки&nbsp;и&nbsp;скверы»</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <AllNatureMonuments />
          <span className="primary">За все места категории «Памятники&nbsp;природы»</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <AllModernBuildings />
          <span className="primary">За все места категории «Современные&nbsp;сооружения»</span>
          <span>25 баллов</span>
        </div>
        <div className="level">
          <First10Acheivements />
          <span className="primary">За 10&nbsp;достижений</span>
          <span>50 баллов</span>
        </div>
        <div className="level">
          <First50Objects />
          <span className="primary">Посетить 50&nbsp;мест</span>
          <span>50 баллов</span>
        </div>
        <div className="level">
          <AllObjects />
          <span className="primary">За 100% открытых объектов</span>
          <span>100 баллов</span>
        </div>
      </div>


      <p>Баллы за открытые места:</p>
      <div className="levels">
        <div className="level">
          <PlaceGreen />
          <span className="primary">За каждый открытый объект зеленого цвета</span>
          <span>10 баллов</span>
        </div>
        <div className="level">
          <PlaceBlue />
          <span className="primary">За каждый открытый объект синего цвета</span>
          <span>24 балла</span>
        </div>
        <div className="level">
          <PlaceRed />
          <span className="primary">За каждый открытый объект красного цвета</span>
          <span>40 баллов</span>
        </div>
      </div>

      <div className="about">
        <p>Следи за новостями в ленте!<br/> Будет интересно!</p>
      </div>
    </TextSection>
  )
}

export default RulesRating

const paragraph1 = `За открытие определенных комбинаций мест Игроку заcчитываются Достижения.`
const paragraph2 = `За открытые места, достижения игрокам начисляются
                    баллы и\xa0формируется рейтинг игроков.`
