import React from 'react'
import TextSection from 'src/sections/TextSection'
//@ts-ignore
import LevelBronze from 'src/public/icons/levelBronze.inline.svg'
//@ts-ignore
import LevelSilver from 'src/public/icons/levelSilver.inline.svg'
//@ts-ignore
import LevelGold from 'src/public/icons/levelGold.inline.svg'
//@ts-ignore
import PlaceRed from 'src/public/icons/PlaceRed.inline.svg'
//@ts-ignore
import PlaceBlue from 'src/public/icons/PlaceBlue.inline.svg'
//@ts-ignore
import PlaceGreen from 'src/public/icons/PlaceGreen.inline.svg'
//@ts-ignore
import MedalBronze from 'src/public/icons/medalBronze.inline.svg'
//@ts-ignore
import MedalSilver from 'src/public/icons/medalSilver.inline.svg'
//@ts-ignore
import MedalGold from 'src/public/icons/medalGold.inline.svg'

const RulesLevels = () => {
  return (
    <TextSection id="levels" title="Уровни">
      <p>{paragraph1}</p>
      <p>{paragraph2}</p>
      <div className="levels shrinked">
        <div className="level">
          <LevelBronze />
          <div className="places">
            <div className="place">
              <PlaceRed />
              <span className="red">5</span>
            </div>
            <div className="place">
              <PlaceBlue />
              <span className="blue">10</span>
            </div>
            <div className="place">
              <PlaceGreen />
              <span className="green">20</span>
            </div>
          </div>
          <span>для Бронзового уровня</span>
        </div>
        <div className="level">
          <LevelSilver />
          <div className="places">
            <div className="place">
              <PlaceRed />
              <span className="red">10</span>
            </div>
            <div className="place">
              <PlaceBlue />
              <span className="blue">15</span>
            </div>
            <div className="place">
              <PlaceGreen />
              <span className="green">40</span>
            </div>
          </div>
          <span>для Серебряного уровня</span>
        </div>
        <div className="level">
          <LevelGold />
          <div className="places">
            <div className="place">
              <PlaceRed />
              <span className="red">15</span>
            </div>
            <div className="place">
              <PlaceBlue />
              <span className="blue">20</span>
            </div>
            <div className="place">
              <PlaceGreen />
              <span className="green">55</span>
            </div>
          </div>
          <span>для Золотого уровня</span>
        </div>
      </div>
      <p>{paragraph3}</p>

      <p>{paragraph4}</p>
      <div className="levels">
        <div className="level">
          <MedalBronze />
          <span className="primary">Сувенирный магнит</span>
          <span>за Бронзовый статус</span>
        </div>
        <div className="level">
          <MedalSilver />
          <span className="primary">Наручный браслет</span>
          <span>за Серебряный статус</span>
        </div>
        <div className="level wide">
          <MedalGold />
          <span className="primary">Памятный знак отличия</span>
          <span>за Золотой статус</span>
        </div>
      </div>
      <p>{paragraph5}</p>
      <p>{paragraph6}</p>
      <p className="address">Пункт выдачи наград:</p>
      <p>{paragraph7}</p>
    </TextSection>
  )
}

export default RulesLevels

const paragraph1 = `Открывай больше мест, проходи очередной уровень 
                    и\xa0получай реальные награды.`
const paragraph2 = `Требования уровней по количеству открытых мест:`

const paragraph3 = `Так, например, для получения бронзового уровня тебе необходимо
                     открыть как минимум 5\xa0красных мест\xa0+ 10\xa0синих\xa0+ 20\xa0зеленых.`

const paragraph4 = `Достигнув определенного уровня, ты имеешь право на\xa0награду.`
const paragraph5 = `Награды можно получать как по\xa0одной\xa0-
                    за каждый уровень, так и\xa0по несколько\xa0-
                    за более высокий пройденный уровень и\xa0за предыдущие.`
const paragraph6 = `Для получения наград в\xa0пункте выдачи тебе будет нужен пароль,
                     который появится в\xa0личном профиле после прохождения уровня.`
const paragraph7 = `Бийск, ул.\xa0Льва Толстого,\xa0148,
                    с\xa011:00 до\xa018:00 (кроме праздников, последний день
                     месяца\xa0- санитарный день), 
                    тел.\xa0+7\xa0(3854)\xa035-66-14`
