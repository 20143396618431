import React from 'react'
import TextSection from 'src/sections/TextSection'
import Footer from 'src/layout/Footer'

const RulesAttention = () => {
  const title = <span className="attention">Важно!</span>
  return (
    <TextSection id="attention" title={title}>
      <p>{paragraph1}</p>
      <div className="spacing"></div>
      <Footer />
    </TextSection>
  )
}

export default RulesAttention

const paragraph1 = `Поиск и\xa0открытие мест связаны с\xa0перемещением 
                    по\xa0открытому пространству. 
                    Будьте внимательны и\xa0осмотрительны, соблюдайте 
                    правила дорожного движения.`
