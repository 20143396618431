import React from 'react'
import H1 from 'src/components/Typography/H1'
import Wrapper from './style'

interface Props {
  title: string
}

const PageTitle:React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <H1>{props.title}</H1>
    </Wrapper>
  )
}

export default PageTitle
